import React from 'react'
import PropTypes from 'prop-types'
import { Radio, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    paddingLeft: 0,
    '&$checked': {
      color: theme.palette.text.primary
    }
  },
  checked: {}
}))

function RadioButton({ value = '', checked = false, onChange = () => {} }) {
  const classes = useStyles()
  return <Radio size="small" value={value} onChange={onChange} checked={checked} classes={classes} />
}

RadioButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool
}

export { RadioButton }
