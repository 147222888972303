import React from 'react'
import PropTypes from 'prop-types'
import { Button as ButtonMaterial, makeStyles } from '@material-ui/core'
import { Flex } from 'src/Components/Containers'
import { Metrics } from 'src/Theme'
import { darken } from 'polished'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 46,
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22px',
    textTransform: 'none'
  },
  textPrimary: {
    border: 0,
    textTransform: 'none'
  },
  sizeSmall: {
    height: Metrics.small
  },
  sizeLarge: {
    height: Metrics.large
  },
  containedPrimary: {
    color: theme.palette.background.default,
    backgroundColor: (props) => {
      return props.alert ? theme.palette.error.main : theme.palette.primary
    },
    '&:hover': {
      backgroundColor: (props) => {
        return props.alert
          ? `${darken(0.03, theme.palette.error.main)}`
          : `${darken(0.03, theme.palette.primary.main)}`
      }
    }
  },
  outlinedPrimary: {
    color: (props) => {
      return props.alert ? theme.palette.error.main : theme.palette.text.primary
    },
    border: (props) => {
      return props.alert
        ? `1px solid ${theme.palette.error.main}`
        : `1px solid ${theme.palette.text.primary}`
    },
    '&:hover': {
      backgroundColor: `${darken(0.015, theme.palette.background.default)}`,
      border: (props) => {
        return props.alert
          ? `1px solid ${theme.palette.error.main}`
          : `1px solid ${theme.palette.text.primary}`
      }
    }
  }
}))

function Button({
  children,
  disabled = false,
  size = 'medium',
  color = 'secondary',
  variant = 'outlined',
  onClick = () => console.log('Button Clicked'),
  width = '100%',
  style = {},
  type = '',
  ...rest
}) {
  const classes = useStyles(rest)

  return (
    <Flex style={{ width: width, ...style}}>
      <ButtonMaterial
        variant={variant}
        disabled={disabled}
        size={size}
        color={color}
        onClick={onClick}
        disableFocusRipple
        disableRipple
        fullWidth
        disableElevation
        classes={{ ...classes }}
        type={type}
      >{children}</ButtonMaterial>
    </Flex>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  faIcon: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  style: PropTypes.object,
  type: PropTypes.string,
  alert: PropTypes.bool
}

export { Button }
