import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

function request() {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: { 'Content-Type': 'application/json', 'app_version': process.env.REACT_APP_VERSION }
  })

  instance.interceptors.request.use(
    async function (config) {
      const headers = await localStorage.getItem('@auth')
      const decamelizedHeaders = decamelizeKeys(JSON.parse(headers), { separator: '-' })
      const decamelizedData = decamelizeKeys(config.data, { separator: '_' }) || {}
      config.data = { ...(decamelizedData.data || {}) }
      config.headers = { ...config.headers, ...(decamelizedHeaders || {}) }

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      return camelizeKeys(response)
    },
    async function (error) {
      if (error?.response?.status === 401 && error?.response?.data?.substatus !== 1) {
        await localStorage.removeItem('@user')
        await localStorage.removeItem('@auth')
      }

      return Promise.reject(error.response?.data || error)
    }
  )

  return instance
}

export default request
