import React, { createContext, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Auth = createContext({})

async function getAuthFromStorage() {
  const auth = await localStorage.getItem('@auth')
  return JSON.parse(auth)
}

function AuthProvider(props) {
  const [auth, setAuth] = useState(null)
  return <Auth.Provider value={{ auth, setAuth }} {...props} />
}

function useAuth() {
  const context = useContext(Auth)
  const { push } = useHistory()

  if (context === undefined) {
    throw new Error('Auth is not defined in async storage')
  }

  const { setAuth = () => {} } = context

  async function save(auth) {
    setAuth(auth)
    await localStorage.setItem('@auth', JSON.stringify(auth))
  }

  async function logout() {
    setAuth()

    await localStorage.removeItem('@user')
    await localStorage.removeItem('@auth')
    await localStorage.removeItem('@onCallToken')
    await localStorage.removeItem('@attendanceStatus')

    push('/login')
  }

  return {
    auth: context.auth,
    save,
    logout
  }
}

export { useAuth, AuthProvider, getAuthFromStorage }
