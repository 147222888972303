import api from './request'

function createAttendance(onCall) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/on_calls`,
      { data: onCall }
    )
    .then((response) => {
      return response.data.data
    })
}

function updateMedicalReport({ medicalReport, medicalReportId }) {
  return api()
    .put(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/medical_reports/${medicalReportId}`,
      { data: { medicalReport } }
    )
    .then((response) => {
      return response.data.data
    })
}

function updateMedicalreportPostSign({ medicalReport, medicalReportId }) {
  return api()
    .put(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/medical_reports/${medicalReportId}`,
      { data: { medicalReport } }
    )
    .then((response) => {
      return response.data.data
    })
}

function finishAttendace({ id }) {
  return api()
    .put(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/attendances/${id}/doctor_finish`,
      {
        data: { attendance: { origin: 'desktop' } }
      }
    )
    .then((response) => {
      return response.data.data
    })
}

function tokenValidate(token) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/on_calls/validate_token`,
      {
        data: {
          onCall: {
            token
          }
        }
      }
    )
    .then((response) => {
      return response.data.data
    })
}

function getCurrentAttendance() {
  return api()
    .get(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/attendances/current`
    )
    .then((response) => {
      return response.data.data
    })
}

function getCurrentOnCall() {
  return api()
    .get(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/on_calls/current`
    )
    .then((response) => {
      return response.data.data
    })
}

function createPrescription({ prescription }) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/prescriptions`,
      { data: prescription }
    )
    .then((response) => {
      return response.data.data
    })
}

function signPrescription({ prescriptionId, otp }) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/prescriptions/${prescriptionId}/sign`,
      { data: { otp } }
    )
    .then((response) => {
      return response.data.data
    })
}

function createMedicalReport({ medicalReport }) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/medical_reports`,
      { data: { medicalReport } }
    )
    .then((response) => {
      return response.data.data
    })
}

function signMedicalReport({ medicalReportId, otp }) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/medical_reports/${medicalReportId}/sign`,
      { data: { otp } }
    )
    .then((response) => {
      return response.data.data
    })
}


function createAttestation(medicalAttestation) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/medical_attestations`,
      { data: medicalAttestation }
    )
    .then((response) => {
      return response.data.data
    })
}


function signAttestation({ attestationId, otp}) {
  return api()
    .post(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/medical_attestations/${attestationId}/sign`,
      { data: { otp } }
    )
    .then((response) => {
      return response.data.data
    })
}

function getPatientData(attendanceId) {
  return api()
    .get(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/attendances/${attendanceId}/patient_data`
    )
    .then((response) => {
      return response.data.data
    })
}

function getAttendance(attendanceId) {
  return api()
    .get(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/attendances/${attendanceId}`
    )
    .then((response) => {
      return response.data.data
    })
}

function updateAttendance({ attendance }) {
  return api()
    .put(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/doctors/attendances/${attendance?.id}`, {
        data: {
          attendance
        }
      }
    )
    .then((response) => {
      return response.data.data
    })
}

function getProblableDiagnosis(searchWord) {
  return api()
    .get(
      `/${process.env.REACT_APP_API_VERSION}/companies/${process.env.REACT_APP_COMPANY_ID}/probable_diagnoses?q[name_or_cid_code_cont]=${searchWord}&per_page=300`
    )
    .then((response) => {
      return response.data.data
    })
}




export {
  createAttendance,
  finishAttendace,
  tokenValidate,
  getCurrentOnCall,
  getCurrentAttendance,
  createPrescription,
  createMedicalReport,
  createAttestation,
  getPatientData,
  getAttendance,
  updateAttendance,
  signAttestation,
  signMedicalReport,
  signPrescription,
  updateMedicalReport,
  updateMedicalreportPostSign,
  getProblableDiagnosis
}
