import { createMuiTheme } from '@material-ui/core'

import Colors from './Colors'
import Typography from './Typography'

const Theme = createMuiTheme({
  typography: {
    ...Typography
  },
  palette: {
    primary: {
      main: Colors.primary
    },
    secondary: {
      main: Colors.secondary,

    },
    text: {
      primary: Colors.text,
      secondary: Colors.subTitle,
      disabled: Colors.disabled,
      light: Colors.accent,
      hint: Colors.textCard,
    },
    error: {
      main: Colors.error,
      contrastText: Colors.background
    },
    success: {
      main: Colors.success,
    },
    focused: {
      main: Colors.light
    },
    background: {
      default: Colors.background,
      paper: Colors.backgroundCard
    },
    warning: {
      main: Colors.warning
    },
    grey: {
      50:  Colors.borders.light,
      100: Colors.backgroundSelect,
      200: Colors.borders.thin,
      300: Colors.borders.normal,
    },
    breakpoints: {
      keys: ['sm', 'md', 'lg'],
      values: {
        sm: 0,
        md: 640,
        lg: 1024,
        xl: 1440
      }
    }
  }
})


export default Theme
