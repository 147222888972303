import React, { createContext, useContext, useState } from 'react'

const Attendance = createContext({})

async function getAttendanceFromStorage() {
  const attendance = await localStorage.getItem('@attendance')
  return JSON.parse(attendance)
}

function AttendanceProvider(props) {
  const [attendance, setAttendanceState] = useState(null)
  return (
    <Attendance.Provider
      value={{ attendance, setAttendanceState }}
      {...props}
    />
  )
}

function useAttendance() {
  const context = useContext(Attendance)

  if (context === undefined) {
    throw new Error('Attendance is not defined in async storage')
  }

  const { setAttendanceState = () => {} } = context

  async function setAttendance(attendance) {
    setAttendanceState(attendance)
    await localStorage.setItem('@attendance', JSON.stringify(attendance))
  }

  return {
    attendance: context.attendance,
    setAttendance,
  }
}

export { useAttendance, AttendanceProvider, getAttendanceFromStorage }
