import React, { createContext, useContext, useState } from 'react'

const OnCall = createContext({})

async function getOnCallFromStorage() {
  const onCall = await localStorage.getItem('@onCall')
  return JSON.parse(onCall)
}

function OnCallProvider(props) {
  const [onCall, setOncallState] = useState(null)
  return (
    <OnCall.Provider
      value={{ onCall, setOncallState }}
      {...props}
    />
  )
}

function useOnCall() {
  const context = useContext(OnCall)

  if (context === undefined) {
    throw new Error('OnCall is not defined in async storage')
  }

  const { setOncallState = () => {} } = context

  async function setOnCall(onCall) {
    setOncallState(onCall)
    await localStorage.setItem('@onCall', JSON.stringify(onCall))
  }

  return {
    onCall: context.onCall,
    setOnCall
  }
}

export { useOnCall, OnCallProvider, getOnCallFromStorage }
