import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { AppBar } from 'src/Components/Bars'

import { useAuth } from 'src/Contexts/auth.hooks'

function PrivateRoute({ children, ...rest }) {
  const { auth } = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          <React.Fragment>
            <AppBar />
            {children}
          </React.Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node
}

export { PrivateRoute }
