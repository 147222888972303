import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { useErrorMessage, useAlertMessage, useLoading } from 'src/Contexts/api.hooks'
import { useAttendance } from 'src/Contexts/attendance.hooks'
import { useOnCall } from 'src/Contexts/onCall.hooks'
import { useWebSocket } from 'src/Services/websocket-connection.hooks'
import { Dialog } from 'src/Components/Dialogs'
import { getCurrentAttendance } from 'src/Api/attendance.api'

function WebsocketService() {
  const [match, setMatch] = useState(false)
  const [prevStatus, setPrevStatus] = useState('CLOSED')
  const [action, setAction] = useState(null)
  const [matchAttendance, setMatchAttendance] =  useState(null)
  const { setError } = useErrorMessage()
  const { setAlert } = useAlertMessage()
  const { setAttendance } = useAttendance()
  const { onCall, setOnCall } = useOnCall()
  const { setLoading } = useLoading()
  const { push } = useHistory()
  const { STATUS, MESSAGE, closeConnection, openConnection, sendMessage } = useWebSocket()
  const ACTION = MESSAGE?.message?.action
  const MATCH_ATTENDANCE = action === 'match' && MESSAGE?.message?.data?.attendance
  const TOKEN = onCall?.token

  async function acceptAttendance() {
    await setLoading(true)
    await sendMessage({ action: 'accept_attendance', args: { attendance_id: matchAttendance?.id } })
    setMatch(false)
  }

  async function rejectAttendance() {
    await setLoading(true)
    await sendMessage({ action: 'reject_attendance', args: { attendance_id: matchAttendance?.id } })
    setMatch(false)
  }

  useEffect(() => {
    if (ACTION) {
      setAction(ACTION)
    }
  }, [ACTION])

  useEffect(() => {
    if(MATCH_ATTENDANCE) {
      setMatchAttendance(MATCH_ATTENDANCE)
    }
  }, [MATCH_ATTENDANCE])

  useEffect(() => {
    if(onCall) {
      openConnection(onCall?.token)
    } else {
      setMatch(false)
      closeConnection()
    }
  }, [onCall, TOKEN, openConnection, closeConnection])

  useEffect(() => {
    if (action === 'match') {
      setMatch(true)
    } else if (action === 'dismatch') {
      setLoading(false)
      setMatch(false)
    } else if (action === 'accept_attendance') {
      setLoading(false)
      setMatch(false)
      getCurrentAttendanceOnApi()
      push('/attendance')
    } else if (action ===' finish_atttendance') {
      getCurrentAttendanceOnApi()
    }
    // eslint-disable-next-line
  }, [action, match])

  useEffect(() => {
    setPrevStatus(STATUS)
    if (prevStatus === 'CONNECTING' && STATUS === 'OPEN') {
      setMatch(false)
      setAlert(
        `Plantão inciado com sucesso! Inicio: ${dayjs(onCall?.createdAt).format('DD/MM/YYYY HH:mm:ss')}`
      )
    }

    if (prevStatus === 'OPEN' && STATUS === 'CLOSED' && action === 'dismatch') {
      setOnCall(null)
      setError('Você não aceitou seu ultimo atendimento, inicie o plantão novamente')
    }
  }, [STATUS, prevStatus, action, setError, setAlert, onCall, setOnCall])

  function handleMatchedAttendance(accepted) {
    if (accepted) {
      acceptAttendance(accepted)
    } else {
      rejectAttendance(accepted)
    }
  }

  async function getCurrentAttendanceOnApi() {
    setLoading(true)
    try {
      const currentAttendance = await getCurrentAttendance()
      await setAttendance(currentAttendance)
      await setLoading(false)
    } catch (e) {
      setError(e.errors || e.error || 'Erro ao buscar atendimento!')
    } finally {
      setLoading(false)
    }
  }


  return (
    <Dialog
      message="Tem um atedimento a sua espera, deseja aceitar?"
      open={match}
      onPressNo={() => handleMatchedAttendance(false)}
      onPessOk={() => handleMatchedAttendance(true)}
      handleClose={() => {}}
    />
  )
}

export { WebsocketService }
