import React, { useState } from 'react'
import { AppBar as AppBarMaterial, Toolbar, Menu, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LeftDrawer } from 'src/Components/Drawers'
import { PrimaryLogo } from 'src/Components/Logo'
import { ImageAvatar } from 'src/Components/Avatar'
import { Flex } from 'src/Components/Containers'
import { Helpers, Theme } from 'src/Theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faDoorClosed } from '@fortawesome/pro-solid-svg-icons'

import { useAuth } from 'src/Contexts/auth.hooks'
import { useUser } from 'src/Contexts/user.hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    paddingLeft: 42,
    paddingRight: 42,
    zIndex: theme.zIndex.drawer + 1
  },
  colorDefault: {
    backgroundColor: Theme.palette.background.default
  }
}))

function AppBar() {
  const [showProfileMenu, setShowProfileMenu] = useState(false)
  const [iconLogoutOpened, setIconLogoutOpened] = useState(false)
  const { logout } = useAuth()
  const { user } = useUser()
  const classes = useStyles()

  function handleProfileMenu(event) {
    setShowProfileMenu(event?.currentTarget)
  }

  function closeProfileMenu() {
    setShowProfileMenu(null)
  }

  function toLogout() {
    closeProfileMenu()
    logout()
  }

  return (
    <AppBarMaterial position="fixed" color="default" classes={classes}>
      <Toolbar variant="dense" style={{ ...Helpers.mainSpaceBetween, padding: 0, height: 80 }}>
        <div />
        <Flex style={{ marginLeft: 160 }}>
          <PrimaryLogo height={59} width={150} color={Theme.palette.primary.main} />
        </Flex>
        <Flex style={{ ...Helpers.center }}>
          <Typography
            style={{
              paddingRight: 8,
              color: Theme.palette.text.primary,
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '21px',
              letterSpacing: '0.1px'
            }}>
            {user?.firstName}
          </Typography>
          <ImageAvatar alt="doctor-profile-avatar" src={user?.avatarUrl} />
          {iconLogoutOpened ? (
            <FontAwesomeIcon
              color={Theme.palette.text.primary}
              icon={faDoorOpen}
              style={{ width: 23, height: 'auto', marginLeft: 40, cursor: 'pointer' }}
              onClick={handleProfileMenu}
              onMouseLeave={() => setIconLogoutOpened(false)}
            />
          ) : (
            <FontAwesomeIcon
              color={Theme.palette.text.primary}
              icon={faDoorClosed}
              style={{ width: 23, height: 'auto', marginLeft: 40, cursor: 'pointer' }}
              onClick={handleProfileMenu}
              onMouseEnter={() => setIconLogoutOpened(true)}
            />
          )}
          <Menu
            anchorEl={showProfileMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(showProfileMenu)}
            onClose={closeProfileMenu}>
            <MenuItem onClick={toLogout}>Logout</MenuItem>
          </Menu>
        </Flex>
      </Toolbar>
      <LeftDrawer />
    </AppBarMaterial>
  )
}

export { AppBar }
