import React, { createContext, useContext, useState } from 'react'

const ApiContext = createContext({ isLoading: false, errorMessage: '', alertMessage: {} })

function getApiContextFromStorage() {
  return localStorage.getItem('@api')
}

function ApiProvider(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [alertMessage, setAlertMessage] = useState('')

  return (
    <ApiContext.Provider
      value={{
        isLoading,
        setIsLoading,
        errorMessage,
        setErrorMessage,
        alertMessage,
        setAlertMessage
      }}
      {...props}
    />
  )
}

function useLoading() {
  const context = useContext(ApiContext)

  if (context === undefined) {
    throw new Error('ApiContext is not defined in async storage')
  }

  const { setIsLoading } = context

  function setLoading(isLoading) {
    setIsLoading(isLoading)
    localStorage.setItem('@api', JSON.stringify({ ...context, isLoading }))
  }

  return { isLoading: context.isLoading, setLoading }
}

function useErrorMessage() {
  const context = useContext(ApiContext)

  if (context === undefined) {
    throw new Error('ApiContext is not defined in async storage')
  }

  const { setErrorMessage } = context

  function setError(errorMessage) {
    setErrorMessage(errorMessage)
    localStorage.setItem('@api', JSON.stringify({ ...context, errorMessage }))
  }

  return { errorMessage: context.errorMessage, setError }
}

function useAlertMessage() {
  const context = useContext(ApiContext)

  if (context === undefined) {
    throw new Error('ApiContext is not defined in async storage')
  }

  const { setAlertMessage } = context

  function setAlert(alertMessage) {
    setAlertMessage(alertMessage)
    localStorage.setItem('@api', JSON.stringify({ ...context, alertMessage }))
  }

  return { alertMessage: context.alertMessage, setAlert }
}

export { useLoading, ApiProvider, getApiContextFromStorage, useErrorMessage, useAlertMessage }
