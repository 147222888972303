import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
function Flex(props) {
  const { children } = props

  return (
    <Box
      {...props}
      style={{
        ...props.style,
        display: 'flex'
      }}>
      {children}
    </Box>
  )
}

Flex.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node
}

export { Flex }
