import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles({
  root: {
    width: 20,
    height: 20,
  },
})

function ImageAvatar({ alt = '', src = '' }) {
  const classes = useStyles()

  return <Avatar alt={alt} src={src} classes={classes} />
}
ImageAvatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string
}

export { ImageAvatar }
