import { Colors } from '.'

export default {
  backgroundReset: {
    backgroundColor: Colors.background
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  colCenter: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  colCross: {
    alignItems: 'center',
    flexDirection: 'column'
  },
  colMain: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  column: {
    flexDirection: 'column'
  },
  columnReverse: {
    flexDirection: 'column-reverse'
  },
  crossCenter: {
    alignItems: 'center'
  },
  crossEnd: {
    alignItems: 'flex-end'
  },
  crossStart: {
    alignItems: 'flex-start'
  },
  crossStretch: {
    alignItems: 'stretch'
  },
  fill: {
    flex: 1
  },
  fillCenter: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  fillCol: {
    flexDirection: 'column'
  },
  fillColCenter: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  fillColCross: {
    alignItems: 'center',
    flexDirection: 'column'
  },
  fillColMain: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  fillColReverse: {
    flexDirection: 'column-reverse'
  },
  fillGrow: {
    flexGrow: 1
  },
  fillRow: {
    flexDirection: 'row'
  },
  fillRowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  fillRowCross: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  fillRowMain: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  fillRowReverse: {
    flexDirection: 'row-reverse'
  },
  fullSize: {
    height: '100%',
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  mainCenter: {
    justifyContent: 'center'
  },
  mainEnd: {
    justifyContent: 'flex-end'
  },
  mainSpaceAround: {
    justifyContent: 'space-around'
  },
  mainSpaceBetween: {
    justifyContent: 'space-between'
  },
  mainStart: {
    justifyContent: 'flex-start'
  },
  mirror: {
    transform: [{ scaleX: -1 }]
  },
  rotate90: {
    transform: [{ rotate: '90deg' }]
  },
  rotate90Inverse: {
    transform: [{ rotate: '-90deg' }]
  },
  row: {
    flexDirection: 'row'
  },
  rowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  rowCross: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  rowMain: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  rowReverse: {
    flexDirection: 'row-reverse'
  },
  scrollSpaceAround: {
    flexGrow: 1,
    justifyContent: 'space-around'
  },
  scrollSpaceBetween: {
    flexGrow: 1,
    justifyContent: 'space-between'
  },
  selfEnd: {
    alignSelf: 'flex-end'
  },
  selfStart: {
    alignSelf: 'flex-start'
  },
  selfStretch: {
    alignSelf: 'stretch'
  },
  textCenter: {
    textAlign: 'center'
  },
  textJustify: {
    textAlign: 'justify'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  }
}
