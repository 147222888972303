import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { useAuth, getAuthFromStorage } from 'src/Contexts/auth.hooks'
import { useUser, getUserFromStorage } from 'src/Contexts/user.hooks'

function PublicRoute({ children, ...rest }) {
  const { save: saveAuth, auth } = useAuth()
  const { save: saveUser, user } = useUser()
  
  useEffect(() => {
    if (!user || !auth) {
      getUser()
    }
  })

  async function getUser() {
    const userFromStorage = await getUserFromStorage()
    const authFromStorage = await getAuthFromStorage()

    if (userFromStorage && authFromStorage) {
      saveUser(userFromStorage)
      saveAuth(authFromStorage)
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user || !auth ? (
          <React.Fragment>
            {children}
          </React.Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

PublicRoute.propTypes = {
  children: PropTypes.node
}

export { PublicRoute }
