import { Colors } from '.'
import Font from './Font'

export default {
  h1: {
    fontFamily: Font.light.fontFamily,
    fontSize: '24px',
    lineHeight: '28px',
    color: Colors.title
  },
  h2: {
    fontFamily: Font.medium.fontFamily,
    fontSize: '20px',
    lineHeight: '24px',
    color: Colors.title
  },
  h3: {
    fontFamily: Font.light.fontFamily,
    fontSize: '18px',
    lineHeight: '22px',
    color: Colors.title
  },
  h4: {
    fontFamily: Font.light.fontFamily,
    fontSize: '16px',
    lineHeight: '18px',
    color: Colors.title
  },
  h5: {
    fontFamily: Font.light.fontFamily,
    fontSize: '14px',
    lineHeight: '16px',
    color: Colors.title
  },
  h6: {
    fontFamily: Font.medium.fontFamily,
    fontSize: '12px',
    lineHeight: '18px',
    color: Colors.title
  },
  subtitle1: {
    fontFamily: Font.regular.fontFamily,
    fontSize: '18px',
    lineHeight: '26px',
    color: Colors.text
  },
  body1: {
    fontFamily: Font.regular.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.text
  },
  body2: {
    fontFamily: Font.regular.fontFamily,
    fontSize: '14px',
    lineHeight: '20px',
    color: Colors.text
  }
}
