import React, { useEffect, Suspense, lazy } from 'react'
import { Switch } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Loading } from 'src/Components/Loading'
import { PrivateRoute, PublicRoute } from 'src/Components/Routes'
import { useLoading, useErrorMessage, useAlertMessage } from 'src/Contexts/api.hooks'

const Login = lazy(() => import('./Login'))
const OnCall = lazy(() => import('./OnCall'))
const Attendance = lazy(() => import('./Attendance'))
const History = lazy(() => import('./History'))
const Attendances = lazy(() => import('./Attendances'))


function Pages() {
  const { isLoading } = useLoading()
  const { errorMessage, setError } = useErrorMessage()
  const { alertMessage, setAlert } = useAlertMessage()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
    }

    return () => {
      setError(null)
    }
  }, [errorMessage, setError, enqueueSnackbar])

  useEffect(() => {
    if (alertMessage) {
      enqueueSnackbar(alertMessage, {
        variant: 'success'
      })
    }

    return () => {
      setAlert(null)
    }
  }, [alertMessage, setAlert, enqueueSnackbar])

  return (
    <>
      <Loading visible={isLoading} />
      <Suspense fallback={<Loading visible />}>
        <Switch>
          <PublicRoute path="/login">
            <Login />
          </PublicRoute>
          <PrivateRoute path="/oncall">
            <OnCall />
          </PrivateRoute>
          <PrivateRoute path="/attendance">
            <Attendance />
          </PrivateRoute>
          <PrivateRoute path="/attendances/:id">
            <Attendances />
          </PrivateRoute>
          <PrivateRoute path="/history">
            <History />
          </PrivateRoute>
          <PrivateRoute path="/">
            <OnCall />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </>
  )
}

export { Pages }
