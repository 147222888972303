import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSlash } from '@fortawesome/pro-solid-svg-icons'
import { Flex } from 'src/Components/Containers'
import { Theme } from 'src/Theme'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 60,
    padding: '0px 16px 0px 16px',
    height: 34,
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '24px',
    color: Theme.palette.text.light,
    textTransform: 'capitalize'
  }
}))

function ButtonEndService({
  children,
  disabled = false,
  size = 'medium',
  onClick = () => console.log('Button Clicked'),
  width = '186px',
  style = {},
  type = '',
  ...rest
}) {
  const classes = useStyles(rest)

  return (
    <Flex style={{ width: width, ...style }}>
      <Box style={{ borderRadius: 60 }} boxShadow={3}>
        <Button
          style={{ backgroundColor: Theme.palette.error.main }}
          disabled={disabled}
          size={size}
          onClick={onClick}
          disableFocusRipple
          disableRipple
          fullWidth
          disableElevation
          classes={{ ...classes }}
          type={type}
          endIcon={<FontAwesomeIcon icon={faPhoneSlash} style={{ height: 20, width: 20 }} />}>
          {children}
        </Button>
      </Box>
    </Flex>
  )
}

ButtonEndService.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  style: PropTypes.object,
  type: PropTypes.string
}

export { ButtonEndService }
