import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog as DialogMaterial,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide
} from '@material-ui/core'
import { Button } from 'src/Components/Buttons'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Dialog({
  open = false,
  handleClose = () => {},
  message = '',
  onPressNo = () => {},
  onPessOk = () => {}
}) {
  function handleNegativeButton() {
    onPressNo()
    handleClose()
  }

  return (
    <DialogMaterial
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleNegativeButton}>
          Recusar
        </Button>
        <Button onClick={onPessOk} color="primary" variant="contained">
          Aceitar
        </Button>
      </DialogActions>
    </DialogMaterial>
  )
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onPressNo: PropTypes.func.isRequired,
  onPessOk: PropTypes.func.isRequired
}

export { Dialog }
