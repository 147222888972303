import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.1) !important'
  }
}))

function Loading({ visible }) {
  const classes = useStyles()

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      open={visible}
      className={classes.modal}
      BackdropProps={{
        className: classes.backdrop
      }}>
      <CircularProgress size={48} />
    </Modal>
  )
}

Loading.propTypes = {
  visible: PropTypes.bool,
  borderRadius: PropTypes.number,
  backdropColor: PropTypes.string,
  backdropOpacity: PropTypes.number
}

export { Loading }
