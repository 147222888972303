import { useState, useEffect } from 'react'
import { camelizeKeys } from 'humps'
import { useErrorMessage } from 'src/Contexts/api.hooks'
import { useAuth } from 'src/Contexts/auth.hooks'

const statusCodes = {
  3: 'CLOSED',
  2: 'CLOSING',
  0: 'CONNECTING',
  1: 'OPEN'
}

function useWebSocket() {
  const [WS, setWS] = useState(null)
  const [STATUS, setStatus] = useState('CLOSED')
  const [ERROR, setError] = useState(null)
  const [MESSAGE, setMessage] = useState(null)
  const [PROTOCOL, setProtocol] = useState(null)
  const readyConnection = STATUS === 'OPEN' && WS
  const { setError: setErrorContext } = useErrorMessage()
  const { auth } = useAuth()

  function openConnection(token) {
    createWebSocket(token)
  }

  async function sendMessage(message) {
    if (readyConnection) {
      await WS.send(
        JSON.stringify({
          command: 'message',
          identifier: JSON.stringify({ channel: 'DoctorDesktopChannel' }),
          data: JSON.stringify(message)
        })
      )
    }
  }

  function closeConnection() {
    if (readyConnection) {
      WS.close()
    }
  }

  async function createWebSocket(token) {
    try {
      if (!WS) {
        const ws = await new WebSocket(
          `${process.env.REACT_APP_API_URL?.replace('/api', '').replace(
            'https',
            'wss'
          )}/cable?access-token=${encodeURIComponent(
            auth?.accessToken
          )}&client=${encodeURIComponent(auth?.client)}&uid=${encodeURIComponent(
            auth?.uid
          )}&on-call-token=${encodeURIComponent(token)}`
        )
  
        setWS(ws)
      }
    } catch(e) {
      Promise.reject(e)
    }
  }

  useEffect(() => {
    if (WS) {
      WS.onopen = function() {
        WS.send(
          JSON.stringify({
            command: 'subscribe',
            identifier: JSON.stringify({ channel: 'DoctorDesktopChannel' })
          })
        )
        setStatus('OPEN')
        setError(null)
      }

      WS.onclose = function() {
        setStatus('CLOSED')
        setError(null)
        setProtocol(null)
        setMessage(null)
        setWS(null)
      }

      WS.onerror = function(e) {
        setErrorContext(e.message || 'Erro desconhecido, tente novamente!')
        setError(e.message)
        if (e.status === 401) {
          localStorage.removeItem('@user')
          localStorage.removeItem('@auth')
        }
      }

      WS.onmessage = message => {
        setMessage(camelizeKeys(JSON.parse(message?.data), { separator: '-' }))
      }
    }
  }, [WS, setErrorContext])

  useEffect(() => {
    setStatus(statusCodes[WS?.readyState] || 'CLOSED')
  }, [WS])

  return { STATUS, ERROR, MESSAGE, PROTOCOL, sendMessage, closeConnection, openConnection }
}

export { useWebSocket }
