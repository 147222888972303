import React from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'
import { Theme } from 'src/Theme'
import './App.css'
import { Pages } from './Pages'

import { AuthProvider } from './Contexts/auth.hooks'
import { UserProvider } from './Contexts/user.hooks'
import { ApiProvider } from './Contexts/api.hooks'
import { AttendanceProvider } from './Contexts/attendance.hooks'
import { OnCallProvider } from './Contexts/onCall.hooks'
import { WebsocketService } from './Services/websocket'
import DayJsUtils from '@date-io/dayjs'

function App() {
  return (
    <ApiProvider>
      <AuthProvider>
        <UserProvider>
          <OnCallProvider>
            <AttendanceProvider>
              <MuiThemeProvider theme={Theme}>
                <MuiPickersUtilsProvider utils={DayJsUtils}>
                  <SnackbarProvider maxSnack={8}>
                    <WebsocketService />
                    <Pages />
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </AttendanceProvider>
          </OnCallProvider>
        </UserProvider>
      </AuthProvider>
    </ApiProvider>
  )
}

export default App
