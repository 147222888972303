import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'src/Components/Containers'
import { Theme, Helpers } from 'src/Theme'

function Page({ children, style }) {
  return (
    <Flex
      style={{
        ...Helpers.fillCol,
        padding: 80,
        paddingLeft: 120,
        paddingRight: 40,
        backgroundColor: Theme.palette.background.default,
        ...style
      }}>
      {children}
    </Flex>
  )
}

Page.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object
}

export { Page }