const tiny = 8
const verySmall = tiny / 2 // 4
const small = tiny * 2 // 16
const normal = tiny * 3 // 24
const medium = tiny * 4 // 32
const large = tiny * 6 // 48
const veryLarge = tiny * 7 // 56

export default {
  tiny,
  verySmall,
  small,
  normal,
  medium,
  large,
  veryLarge,
  bottomMargin: {
    marginBottom: normal
  },
  mediumBottomMargin: {
    marginBottom: medium
  },

  verySmallTopMargin: {
    marginTop: verySmall
  },
  verySmallBottomMargin: {
    marginBottom: verySmall
  },
  verySmallLeftMargin: {
    marginLeft: verySmall
  },
  verySmallRightMargin: {
    marginRight: verySmall
  },

  smallTopMargin: {
    marginTop: small
  },
  smallBottomMargin: {
    marginBottom: small
  },
  smallLeftMargin: {
    marginLeft: small
  },
  smallRightMargin: {
    marginRight: small
  },

  smallTopPadding: {
    paddingTop: small
  },
  smallBottomPadding: {
    paddingBottom: small
  },
  smallLeftPadding: {
    paddingLeft: small
  },
  smallRightPadding: {
    paddingRight: small
  },

  largeTopPadding: {
    paddingTop: large
  },
  largeBottomPadding: {
    paddingBottom: large
  },
  largeLeftPadding: {
    paddingLeft: large
  },
  largeRightPadding: {
    paddingRight: large
  },

  veryLargeMarginTop: {
    marginTop: veryLarge
  },
  veryLargeMarginBottom: {
    marginBottom: veryLarge
  },
  veryLargeMarginLeft: {
    marginLeft: veryLarge
  },
  veryLargeMarginRight: {
    marginRight: veryLarge
  },

  veryLargeTopPadding: {
    paddingTop: veryLarge
  },
  veryLargeBottomPadding: {
    paddingBottom: veryLarge
  },
  veryLargeLeftPadding: {
    paddingLeft: veryLarge
  },
  veryLargeRightPadding: {
    paddingRight: veryLarge
  },

  tinyMarginTop: {
    marginTop: tiny
  },
  tinyMarginBottom: {
    marginBottom: tiny
  },
  tinyMarginLeft: {
    marginLeft: tiny
  },
  tinyMarginRight: {
    marginRight: tiny
  },

  normalMarginTop: {
    marginTop: normal
  },
  normalMarginBottom: {
    marginBottom: normal
  },
  normalMarginLeft: {
    marginLeft: normal
  },
  normalMarginRight: {
    marginRight: normal
  },

  normalPaddingTop: {
    paddingTop: normal
  },
  normalPaddingBottom: {
    paddingBottom: normal
  },
  normalPaddingLeft: {
    paddingLeft: normal
  },
  normalPaddingRight: {
    paddingRight: normal
  },

  tinyMargin: {
    margin: tiny
  },
  smallMargin: {
    margin: small
  },
  normalMargin: {
    margin: normal
  },
  mediumMargin: {
    margin: medium
  },

  tinyPaddingTop: {
    paddingTop: tiny
  },
  tinyPaddingBottom: {
    paddingBottom: tiny
  },
  tinyPaddingLeft: {
    paddingLeft: tiny
  },
  tinyPaddingRight: {
    paddingRight: tiny
  },

  tinyPadding: {
    padding: tiny
  },
  smallPadding: {
    padding: small
  },
  normalPadding: {
    padding: normal
  },
  mediumPadding: {
    padding: medium
  }
}
