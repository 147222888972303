import React, { createContext, useContext, useState } from 'react'

const User = createContext(null)

async function getUserFromStorage() {
  const user = await localStorage.getItem('@user')
  return JSON.parse(user)
}

function UserProvider(props) {
  const [user, setUser] = useState({})
  return <User.Provider value={{ user, setUser }} {...props} />
}

function useUser() {
  const context = useContext(User)

  if (context === undefined) {
    throw new Error('User is not defined in async storage')
  }

  const { setUser } = context

  async function save(user) {
    setUser(user)
    await localStorage.setItem('@user', JSON.stringify(user))
  }

  return {
    user: context.user,
    save
  }
}

export { useUser, UserProvider, getUserFromStorage }
