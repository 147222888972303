import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Theme } from 'src/Theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardUser, faCabinetFiling } from '@fortawesome/pro-solid-svg-icons'
import { faToggleOff, faToggleOn } from '@fortawesome/pro-duotone-svg-icons'
import { useOnCall } from 'src/Contexts/onCall.hooks'
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0.5px 0.5px 10px 0.5px #ececec',
    paddingTop: 80,
    borderRight: 0
  },
  drawerOpen: {
    width: 240
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 80
  }
}))
function LeftDrawer({ closeDrawer = () => {} }) {
  const [visible, setVisible] = useState(false)
  const [menuSelected, setMenuSelected] = useState('/oncall')
  const { onCall } = useOnCall()
  const { push } = useHistory()
  const classes = useStyles()

  function list() {
    return (
      <div role="presentation">
        <List>
          {[
            {
              name: <Typography
                style={{
                  color:
                  menuSelected === '/oncall'
                    ? Theme.palette.primary.main
                    : Theme.palette.text.primary
                }}>
              Plantão
              </Typography>,
              action: () => {
                push('/oncall')
                setMenuSelected('/oncall')
              },
              Component: () =>
                onCall ? (
                  <FontAwesomeIcon
                    icon={faToggleOn}
                    style={{ marginLeft: 16, marginRight: 32, width: 22, height: 'auto' }}
                    color={Theme.palette.success.main}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faToggleOff}
                    style={{ marginLeft: 16, marginRight: 32, width: 22, height: 'auto' }}
                    color={Theme.palette.text.secondary}
                  />
                )
            },
            {
              name: (
                <Typography
                  style={{
                    color:
                      menuSelected === '/attendance'
                        ? Theme.palette.primary.main
                        : Theme.palette.text.primary
                  }}>
                  Atendimento
                </Typography>
              ),
              action: () => {
                push('/attendance')
                setMenuSelected('/attendance')
              },
              Component: () => (
                <FontAwesomeIcon
                  icon={faClipboardUser}
                  style={{ marginLeft: 16, marginRight: 32, width: 18, height: 'auto' }}
                  color={
                    menuSelected === '/attendance'
                      ? Theme.palette.primary.main
                      : Theme.palette.text.primary
                  }
                />
              )
            },
            {
              name: (
                <Typography
                  style={{
                    color:
                      menuSelected === '/history'
                        ? Theme.palette.primary.main
                        : Theme.palette.text.primary
                  }}>
                  Histórico
                </Typography>
              ),
              action: () => {
                push('/history')
                setMenuSelected('/history')
              },
              Component: () => (
                <FontAwesomeIcon
                  icon={faCabinetFiling}
                  style={{ marginLeft: 16, marginRight: 32, width: 18, height: 'auto' }}
                  color={
                    menuSelected === '/history'
                      ? Theme.palette.primary.main
                      : Theme.palette.text.primary
                  }
                />
              )
            }
          ].map((item, i) => (
            <ListItem button key={`${item.name}-${i}`} onClick={() => item.action()}>
              <ListItemIcon>
                <item.Component />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </div>
    )
  }

  return (
    <Drawer
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: visible,
        [classes.drawerClose]: !visible
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: visible,
          [classes.drawerClose]: !visible
        })
      }}
      variant="permanent"
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
      anchor="left"
      open={visible}
      onClose={closeDrawer}>
      {list()}
    </Drawer>
  )
}
LeftDrawer.propTypes = {
  visible: PropTypes.bool,
  closeDrawer: PropTypes.func
}
export { LeftDrawer }