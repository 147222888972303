import React from 'react'
import { Theme } from 'src/Theme'
import PropTypes from 'prop-types'

function PrimaryLogo({ color = Theme.colors.primary, width = 0, height = 0 }) {
  return (
    <img style={{
      width: width,
      height: height,
    }} src="/logo.png" />
  )
}

PrimaryLogo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export { PrimaryLogo }
