const Colors = {
  transparent: 'rgba(0,0,0,0)',
  blue: {
    veryLight: '#88ACFF',
    softLight: '#30AFCC',
    light: '#8788F5',
    medium: '#30AFCC',
    dark: '#4547A8'
  },
  white: {
    medium: '#FFFFFF',
    soft: '#FAFAFA',
    mediumDark: '#F0F0F0'
  },
  yellow: {
    medium: '#FABE09'
  },
  gray: {
    veryLight: '#EFF2F7',
    light: '#E6E9F0',
    medium: '#D4DAE3',
    soft: '#ADB5BD',
    softMedium: '#9E9E9E',
    softDark: '#6C7686',
    mediumDark: 'rgba(108, 118, 134, 0.7)',
    dark: '#494F71'
  },
  green: {
    medium: '#36B37E'
  },
  red: {
    medium: '#FF0033'
  }
}

export default {
  primary: Colors.blue.medium,
  secondary: Colors.gray.light,
  accent: Colors.white.medium,
  background: Colors.white.medium,
  backgroundCard: Colors.white.soft,
  backgroundSelect: Colors.white.mediumDark,
  light: Colors.white.medium,
  success: Colors.green.medium,
  error: Colors.red.medium,
  warning: Colors.yellow.medium,
  disabled: Colors.gray.light,
  buttonDisabled: Colors.gray.soft,
  textDisabled: Colors.gray.mediumDark,
  textCard: Colors.gray.softDark,
  link: Colors.blue.softLight,
  text: Colors.gray.dark,
  title: Colors.gray.dark,
  subTitle: Colors.gray.softDark,
  borders: {
    focused: Colors.blue.veryLight,
    normal: Colors.gray.medium,
    light: Colors.gray.veryLight,
    thin: Colors.gray.soft,
    dark: Colors.gray.dark
  },
  placeHolder: Colors.gray.dark,
  adornment: Colors.gray.soft,
  backdrop: Colors.transparent
}
